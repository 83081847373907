import React, { useState, useEffect, useCallback, useRef, useContext, useTransition } from 'react';
import Listing from './Listing';
import Calendar from './Calendar';
import FindAvailabilityModal from './modals/FindAvailabilityModal';
import { generateDatesForMonth } from '../utils/dateUtils';
import moment from 'moment';
import debounce from 'lodash.debounce';

import {
  PropertyList,
  CalendarResponse,
  Listing as ListingType,
  CalendarPMS
} from '../api/type';
import { fetchCalendarDataVFour } from '../api/request';

import leftIcon from './assets/arrow-left.svg';
import rightIcon from './assets/arrow-right.svg';
import { ImportantOrgContext } from '../App';
import BookingSidebar from './sidebars/BookingSidebar';
import ReservationSidebar from './sidebars/ReservationSidebar';
import JobSidebar from './sidebars/JobSidebar';
import JobDetailSidebar from './sidebars/JobDetailSidebar';
import MoreJobDetailSidebar from './sidebars/MoreJobDetailSidebar';
import CustomMonthDropdown from './dropdown/CustomMonthDropdown';
import SkeletonRow from './dropdown/SkeletonRow';

interface Job {
  id: string;
  bubbleTaskId: string | null;
  jobType: 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';
  status: string;
  date: string;
  startDate: string;
  endDate: string;
  duration: string;
}

interface CalendarRef {
  scrollToCurrentDate: () => void;
  scrollToFirstDate: () => void;
  scrollToSpecificDate: (targetDate: string) => void;
  scrollToSpecificProperty: (propertyId: string) => void;
}

interface DateRange {
  startDate: string;
  endDate: string;
}

interface Cache {
  [key: string]: {
    calendarData: ListingType[];
    filteredProperties: PropertyList[];
    propertyCount: number;
  };
}

const generateMonthOptions = () => {
  const startMonth = moment('2024-01-01');
  const endMonth = moment('2025-12-31');
  const months = [];
  const current = startMonth.clone();

  while (current.isSameOrBefore(endMonth)) {
    months.push(current.format('MMMM YYYY'));
    current.add(1, 'month');
  }

  return months;
};


const CalendarApp: React.FC = () => {
  const importantOrgId = useContext(ImportantOrgContext);

  const [properties, setProperties] = useState<PropertyList[]>([]);
  const [filteredProperties, setFilteredProperties] = useState<PropertyList[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredSearchTerm, setFilteredSearchTerm] = useState<string>('');
  const [dates, setDates] = useState<string[]>([]);
  const [currentMonth, setCurrentMonth] = useState<string>(moment().format('MMMM YYYY'));
  const [propertyCount, setPropertyCount] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [calendarData, setCalendarData] = useState<ListingType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSecond, setLoadingSecond] = useState<boolean>(false);

  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const [_loadedPages, setLoadedPages] = useState<number[]>([]);
  const loadedPagesRef = useRef<number[]>([]);
  const [limit] = useState<number>(10);
  const [selectedRange, setSelectedRange] = useState<DateRange>({ startDate: '', endDate: '' });
  const MAX_CONCURRENT_REQUESTS = 1;
  const [cache, setCache] = useState<Cache>({});

  const [selectedDate, setSelectedDate] = useState<string>(moment().format("MMMM YYYY"));
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const [isBookingSidebarOpen, setIsBookingSidebarOpen] = useState<boolean>(false);
  const [selectedBooking, setSelectedBooking] = useState<CalendarPMS | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<PropertyList | undefined>(undefined);

  const [isJobSidebarOpen, setIsJobSidebarOpen] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [currentJobs, setCurrentJobs] = useState<Job[]>([]);

  const [isReservationSidebarOpen, setIsReservationSidebarOpen] = useState<boolean>(false);
  const [isJobDetailSidebarOpen, setIsJobDetailSidebarOpen] = useState<boolean>(false);
  const [isMoreDetailSidebarOpen, setIsMoreDetailSidebarOpen] = useState(false);

  const [needToScrollToFirstDate, setNeedToScrollToFirstDate] = useState<boolean>(false);
  const [needToScrollToSpecificDate, setNeedToScrollToSpecificDate] = useState<boolean>(false);
  const [needToScrollToSpecificProperty, setNeedToScrollToSpecificProperty] = useState<boolean>(false);
  const [specificDate, setSpecificDate] = useState<string | undefined>();
  const [specificPropertyId, setSpecificPropertyId] = useState<string | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  const [newlyAddedReservationId, setNewlyAddedReservationId] = useState<string | null>(null);
  const [newlyAddedJobId, setNewlyAddedJobId] = useState<string | null>(null);
  const [monthOptions] = useState<string[]>(generateMonthOptions());

  const [isPending, startTransition] = useTransition();
  const hasScrolledToToday = useRef(false);

  const closeBookingSidebar = useCallback(() => {
    setSelectedBooking(null);
    setSelectedProperty(undefined);
    setIsBookingSidebarOpen(false);
  }, []);

  const closeJobSidebar = useCallback(() => {
    setIsJobSidebarOpen(false);
    setSelectedDate(moment().format("MMMM YYYY"));
    setSelectedProperty(undefined);
  }, []);

  const closeReservationSidebar = useCallback(() => {
    setIsReservationSidebarOpen(false);
    setSelectedDate(moment().format("MMMM YYYY"));
    setSelectedProperty(undefined);
  }, []);

  const closeJobDetailSidebar = useCallback(() => {
    setIsJobDetailSidebarOpen(false);
    setSelectedJob(null);
    setSelectedProperty(undefined);
  }, []);

  const closeMoreDetailSidebar = useCallback(() => {
    setIsMoreDetailSidebarOpen(false);
    setCurrentJobs([]);
    setSelectedProperty(undefined);
  }, []);

  const closeAllSidebars = useCallback(() => {
    if (isJobSidebarOpen) closeJobSidebar();
    if (isReservationSidebarOpen) closeReservationSidebar();
    if (isBookingSidebarOpen) closeBookingSidebar();
    if (isJobDetailSidebarOpen) closeJobDetailSidebar();
    if (isMoreDetailSidebarOpen) closeMoreDetailSidebar();
  }, [
    isJobSidebarOpen,
    isReservationSidebarOpen,
    isBookingSidebarOpen,
    isJobDetailSidebarOpen,
    isMoreDetailSidebarOpen,
    closeJobSidebar,
    closeReservationSidebar,
    closeBookingSidebar,
    closeJobDetailSidebar,
    closeMoreDetailSidebar,
  ]);

  const openBookingSidebar = useCallback(
    (property: PropertyList | undefined, booking: CalendarPMS) => {
      closeAllSidebars();
      setSelectedBooking(booking);
      setSelectedProperty(property);
      setIsBookingSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const openJobSidebar = useCallback(
    (property: PropertyList | undefined, date: string) => {
      closeAllSidebars();
      setSelectedDate(date);
      setSelectedProperty(property);
      setIsJobSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const openReservationSidebar = useCallback(
    (property: PropertyList | undefined, date: string) => {
      closeAllSidebars();
      setSelectedDate(date);
      setSelectedProperty(property);
      setIsReservationSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const openJobDetailSidebar = useCallback(
    (job: Job, property: PropertyList | undefined) => {
      closeAllSidebars();
      setSelectedJob(job);
      setSelectedProperty(property);
      setIsJobDetailSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const openMoreDetailSidebar = useCallback(
    (jobList: Job[], property: PropertyList | undefined) => {
      closeAllSidebars();
      setCurrentJobs(jobList);
      setSelectedProperty(property);
      setIsMoreDetailSidebarOpen(true);
    },
    [closeAllSidebars]
  );

  const calendarRef = useRef<CalendarRef>(null);

  // Handling API failures / network error
  const fetchWithRetry = useCallback(
    async (
      fn: () => Promise<CalendarResponse>,
      retries = 3,
      delay = 1000
    ): Promise<CalendarResponse> => {
      try {
        setError(null);
        setIsRetrying(false);
        return await fn();
      } catch (err: any) {
        if (retries === 0) {
          if (err.message?.includes('Network')) {
            setError(`Retry limit reached. Network seems to be down. Please check your connection.`);
          } else if (err.response?.status >= 500) {
            setError('Server error. Please try again later.');
          } else if (err.response?.status === 404) {
            setError('The requested data could not be found.');
          } else {
            setError('An unknown error occurred. Please try again.');
          }
          throw err;
        }

        setIsRetrying(true);
        console.warn(`Retrying... Attempts left: ${retries}`);
        await new Promise((res) => setTimeout(res, delay));
        return fetchWithRetry(fn, retries - 1, delay);
      }
    },
    []
  );
  const fetchCalendarDataForMonth = useCallback(
    async (
      month: string,
      year: string,
      pageToFetch: number,
      useFreshData: boolean = false
    ): Promise<boolean> => {
      const monthKey = `${month} ${year}`;

      if (loadedPagesRef.current.includes(pageToFetch)) return false;

      try {
        if (pageToFetch === 1 && (!cache[monthKey] || useFreshData)) {
          setLoading(true);
          setCalendarData([]);
          setProperties([]);
          setFilteredProperties([]);
          setPropertyCount(0);
          setLoadedPages([]);
          loadedPagesRef.current = [];
        }

        const startDate = moment(`${year}-${month}-01`).startOf('month').format('YYYY-MM-DD');
        const endDate = moment(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');

        const calendarResponse: CalendarResponse = await fetchWithRetry(() =>
          fetchCalendarDataVFour(startDate, endDate, importantOrgId, pageToFetch, limit, '', useFreshData)
        );

        if (!calendarResponse.data) {
          setLoading(false);
          console.error('No data available in calendar response.');
          return false;
        }

        const { properties = [], reservations = [] } = calendarResponse.data;

        if (properties.length === 0) {
          setLoading(false);
          setLoadingSecond(false);
          return false;
        }

        const mappedData: ListingType[] = properties.map((property) => {
          const calendarItem = reservations.find((cd) => cd.calendarPropertyId === property.id);
          return {
            calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
            calendarPropertyId: property.id,
            propertyAddress: property.address,
            propertyTitle: property.name,
            jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
          };
        });

        setCalendarData((prevData) => (pageToFetch === 1 ? mappedData : [...prevData, ...mappedData]));
        setProperties((prevProps) => (pageToFetch === 1 ? properties : [...prevProps, ...properties]));
        setFilteredProperties((prevProps) => (pageToFetch === 1 ? properties : [...prevProps, ...properties]));
        setPropertyCount((prevCount) => (pageToFetch === 1 ? properties.length : prevCount + properties.length));

        setLoadedPages((prevPages) => {
          const newPages = [...prevPages, pageToFetch];
          loadedPagesRef.current = newPages;
          return newPages;
        });

        setCache((prevCache) => ({
          ...prevCache,
          [monthKey]: {
            calendarData: pageToFetch === 1 ? mappedData : [...prevCache[monthKey]?.calendarData || [], ...mappedData],
            filteredProperties: pageToFetch === 1 ? properties : [...prevCache[monthKey]?.filteredProperties || [], ...properties],
            propertyCount: pageToFetch === 1 ? properties.length : (prevCache[monthKey]?.propertyCount || 0) + properties.length,
          },
        }));

        if (pageToFetch === 1) {
          setLoading(false);
          setLoadingSecond(false);
        }
        return true;
      } catch (err) {
        setError('Failed to load calendar data. Please try again!');
        setLoading(false);
        setLoadingSecond(false);
        console.error(`Error loading data for page ${pageToFetch}`, err);
        return false;
      }
    },
    [cache, importantOrgId, limit, fetchWithRetry]
  );

  const fetchRemainingPages = useCallback(
    async (monthName: string, year: string, useFreshData: boolean = false) => {
      setIsFetchingMore(true);
      let pageToFetch = 2;
      let moreData = true;

      while (moreData) {
        const promises: Promise<boolean>[] = [];

        for (let i = 0; i < MAX_CONCURRENT_REQUESTS; i++) {
          const currentPage = pageToFetch++;
          const promise = fetchCalendarDataForMonth(
            monthName,
            year,
            currentPage,
            useFreshData
          );
          promises.push(promise);
        }

        const results = await Promise.all(promises);
        moreData = results.some((result) => result === true);
      }
      setIsFetchingMore(false);
    },
    [fetchCalendarDataForMonth]
  );

  const resetFilter = () => {

    setNeedToScrollToFirstDate(true);
    setFilteredProperties([]);
    setPropertyCount(0);

    const currentMonthKey = currentMonth;
    const cachedData = cache[currentMonthKey];

    if (cachedData) {
      startTransition(() => {
        setCalendarData(cachedData.calendarData);
        setFilteredProperties(cachedData.filteredProperties);
        setProperties(cachedData.filteredProperties);
        setPropertyCount(cachedData.propertyCount);
        setLoading(false);
        setLoadingSecond(false);
      });
    }

    setIsFiltered(false);
    setSelectedRange({ startDate: '', endDate: '' });
  };

  useEffect(() => {
    const currentYear = moment().year();
    const currentMonthIndex = moment().month();
    const monthName = moment().format('MMMM');
    const year = moment().format('YYYY');
    setDates(generateDatesForMonth(currentYear, currentMonthIndex));

    const monthKey = `${monthName} ${year}`;
    if (cache[monthKey]) {
      // console.log(`Loading data for ${monthKey} from cache.`);
      const cachedData = cache[monthKey];
      setCalendarData(cachedData.calendarData);
      setFilteredProperties(cachedData.filteredProperties);
      setProperties(cachedData.filteredProperties);
      setPropertyCount(cachedData.propertyCount);
      setLoading(false);
      setLoadingSecond(false);
    } else {
      setLoadingSecond(true);
      setLoadedPages([]);
      loadedPagesRef.current = [];
      // Fetch the initial page
      fetchCalendarDataForMonth(monthName, year, 1).then(() => {
        // Fetch remaining pages
        fetchRemainingPages(monthName, year);
      });
    }
  }, [cache, fetchCalendarDataForMonth, fetchRemainingPages]);

  // useEffect(() => {
  //   if (calendarData.length > 0 && currentMonth === moment().format('MMMM YYYY') && !hasScrolledToToday.current) {
  //     scrollToToday();
  //     hasScrolledToToday.current = true;
  //   }
  // }, [calendarData, currentMonth]);

  useEffect(() => {
    if (needToScrollToFirstDate && calendarRef.current) {
      calendarRef.current.scrollToFirstDate();
      setNeedToScrollToFirstDate(false);
    }
  }, [needToScrollToFirstDate]);

  useEffect(() => {
    if (specificDate && needToScrollToSpecificDate && calendarRef.current) {
      calendarRef.current.scrollToSpecificDate(specificDate);
      setNeedToScrollToSpecificDate(false);
    }
  }, [needToScrollToSpecificDate, specificDate]);

  useEffect(() => {
    if (specificPropertyId && needToScrollToSpecificProperty && calendarRef.current) {
      calendarRef.current.scrollToSpecificProperty(specificPropertyId);
      setNeedToScrollToSpecificProperty(false);
    }
  }, [needToScrollToSpecificProperty, specificPropertyId]);

  useEffect(() => {
    if (!isRetrying && error) setError(null);
  }, [isRetrying, error]);

  const debouncedSetFilteredSearchTerm = useCallback(
    debounce((term: string) => {
      startTransition(() => {
        setFilteredSearchTerm(term);
      });
    }, 100),
    []
  );

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    debouncedSetFilteredSearchTerm(event.target.value);
  }, [debouncedSetFilteredSearchTerm]);

  const debouncedFetchMonthData = useCallback(
    debounce((newMonth: string, monthName: string, year: string) => {
      const monthKey = `${monthName} ${year}`;
      if (cache[monthKey]) {
        startTransition(() => {
          setCalendarData(cache[monthKey].calendarData);
          setFilteredProperties(cache[monthKey].filteredProperties);
          setProperties(cache[monthKey].filteredProperties);
          setPropertyCount(cache[monthKey].propertyCount);
          setLoading(false);
          setLoadingSecond(false);
        });
      } else {
        setLoadingSecond(true);
        setLoading(true);
        setCalendarData([]);
        setFilteredProperties([]);
        setProperties([]);
        setPropertyCount(0);
        setLoadedPages([]);
        loadedPagesRef.current = [];

        fetchCalendarDataForMonth(monthName, year, 1).then(() => {
          fetchRemainingPages(monthName, year);
        });
      }
    }, 1500),
    [cache]
  );

  const handleMonthChange = useCallback(
    (direction: 'prev' | 'next') => {
      hasScrolledToToday.current = false;
      const newMonth = moment(currentMonth, 'MMMM YYYY')
        .add(direction === 'next' ? 1 : -1, 'month')
        .format('MMMM YYYY');
      const [monthName, year] = newMonth.split(' ');

      setCurrentMonth(newMonth);
      setDates(generateDatesForMonth(Number(year), moment().month(monthName).month()));
      if (moment().format('MMMM YYYY') !== newMonth) {
        setNeedToScrollToFirstDate(true);
      }

      setLoading(true);
      setCalendarData([]);
      setFilteredProperties([]);
      setProperties([]);
      setPropertyCount(0);
      setLoadedPages([]);
      loadedPagesRef.current = [];

      debouncedFetchMonthData(newMonth, monthName, year);
    },
    [currentMonth, debouncedFetchMonthData]
  );



  const scrollToToday = useCallback(() => {
    if (calendarRef.current) {
      calendarRef.current.scrollToCurrentDate();
    }
  }, []);

  const handleMonthSelectChange = useCallback(
    (selectedMonth: string) => {
      setCurrentMonth(selectedMonth);

      const [monthName, year] = selectedMonth.split(' ');
      const monthIndex = moment().month(monthName).month();
      setDates(generateDatesForMonth(Number(year), monthIndex));

      if (moment().format('MMMM YYYY') !== selectedMonth) {
        setNeedToScrollToFirstDate(true);
      } else {
        scrollToToday();
      }

      const monthKey = `${monthName} ${year}`;
      if (cache[monthKey]) {
        startTransition(() => {
          setCalendarData(cache[monthKey].calendarData);
          setFilteredProperties(cache[monthKey].filteredProperties);
          setProperties(cache[monthKey].filteredProperties);
          setPropertyCount(cache[monthKey].propertyCount);
          setLoading(false);
        });
      } else {
        setCalendarData([]);
        setFilteredProperties([]);
        setProperties([]);
        setPropertyCount(0);
        setLoadedPages([]);
        loadedPagesRef.current = [];

        fetchCalendarDataForMonth(monthName, year, 1).then(() => {
          fetchRemainingPages(monthName, year);
        });
      }
    },
    [cache, scrollToToday, fetchCalendarDataForMonth, fetchRemainingPages]
  );

  const handleTodayClick = useCallback(() => {
    hasScrolledToToday.current = false;
    const todayMonth = moment().format('MMMM YYYY');
    if (currentMonth !== todayMonth) {
      if (calendarRef.current) {
        setNeedToScrollToFirstDate(true);
      }
      const currentYear = moment().year();
      const currentMonthIndex = moment().month();
      const monthName = moment().format('MMMM');
      const year = moment().format('YYYY');
      setDates(generateDatesForMonth(currentYear, currentMonthIndex));
      setCurrentMonth(todayMonth);

      const monthKey = `${monthName} ${year}`;
      if (cache[monthKey]) {
        // console.log(`Loading data for ${monthKey} from cache.`);
        startTransition(() => {
          setCalendarData(cache[monthKey].calendarData);
          setFilteredProperties(cache[monthKey].filteredProperties);
          setProperties(cache[monthKey].filteredProperties);
          setPropertyCount(cache[monthKey].propertyCount);
          setLoading(false);
        });
      } else {
        setCalendarData([]);
        setFilteredProperties([]);
        setProperties([]);
        setPropertyCount(0);
        setLoadedPages([]);
        loadedPagesRef.current = [];

        // Fetch the initial page
        fetchCalendarDataForMonth(monthName, year, 1).then(() => {
          // Fetch remaining pages in the background
          fetchRemainingPages(monthName, year);
        });
      }
    } else {
      scrollToToday();
    }
  }, [currentMonth, cache]);

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const updateProperties = async (checkInDate: string, checkOutDate: string, city: string) => {
    try {
      setLoading(true);
      setIsFiltered(true);
      setSelectedRange({ startDate: checkInDate, endDate: checkOutDate });
      setSpecificDate(checkOutDate);
      setNeedToScrollToFirstDate(true);
      const calendarResponse = await fetchCalendarDataVFour(
        checkInDate,
        checkOutDate,
        importantOrgId,
        1,
        limit,
        city
      );

      if (calendarResponse.data) {
        const { properties = [], reservations = [] } = calendarResponse.data;

        const mappedData: ListingType[] = properties.map((property) => {
          const calendarItem = reservations.find((cd) => cd.calendarPropertyId === property.id);
          return {
            calendarPMSList: calendarItem ? calendarItem.calendarPMSList : [],
            calendarPropertyId: property.id,
            propertyAddress: property.address,
            propertyTitle: property.name,
            jobOccurrenceList: calendarItem ? calendarItem.jobOccurrenceList : [],
          };
        });

        setCalendarData(mappedData);
        setFilteredProperties(properties);
        setProperties(properties);
        setPropertyCount(properties.length);
        if (!calendarResponse.data.properties) {
          setNeedToScrollToFirstDate(true);
        } else {
          setNeedToScrollToSpecificDate(true);
        }
      } else {
        setProperties([]);
        setCalendarData([]);
        setFilteredProperties([]);
        setPropertyCount(0);
        setNeedToScrollToSpecificDate(false);
        setNeedToScrollToFirstDate(true);
      }
    } catch (error) {
      console.error('Error updating properties', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReservationCreated = async (date: string, newReservationId: string | null, propertyId: string) => {
    closeReservationSidebar();
    setSpecificDate('');
    setNewlyAddedReservationId('');
    setSpecificPropertyId('');

    // console.log("handleReservationCreated called with:", date, newReservationId, propertyId);

    const newDateMoment = moment(date, 'YYYY-MM-DD');
    const monthName = newDateMoment.format('MMMM');
    const year = newDateMoment.format('YYYY');

    const monthIndex = newDateMoment.month();
    setDates(generateDatesForMonth(Number(year), monthIndex));
    setCurrentMonth(`${monthName} ${year}`);

    setNeedToScrollToFirstDate(true);

    const monthKey = `${monthName} ${year}`;
    setCache((prevCache) => {
      const newCache = { ...prevCache };
      delete newCache[monthKey];
      return newCache;
    });

    setCalendarData([]);
    setFilteredProperties([]);
    setProperties([]);
    setPropertyCount(0);
    setLoadedPages([]);
    loadedPagesRef.current = [];

    await fetchCalendarDataForMonth(monthName, year, 1, true);
    await fetchRemainingPages(monthName, year, true);

    setSpecificDate(date);
    setSpecificPropertyId(propertyId);

    // console.log("selected reservation id", newReservationId);
    // console.log("selected propertyid", propertyId);

    setNewlyAddedReservationId(newReservationId);

    setNeedToScrollToSpecificDate(true);
    setTimeout(() => {
      setNeedToScrollToSpecificProperty(true);
    }, 2000);
  };

  const handleJobCreated = async (date: string, newJobId: string | null) => {
    closeJobSidebar();

    const newDateMoment = moment(date, 'YYYY-MM-DD');
    const monthName = newDateMoment.format('MMMM');
    const year = newDateMoment.format('YYYY');

    const monthIndex = newDateMoment.month();
    setDates(generateDatesForMonth(Number(year), monthIndex));
    setCurrentMonth(`${monthName} ${year}`);

    setNeedToScrollToFirstDate(true);

    const monthKey = `${monthName} ${year}`;
    setCache((prevCache) => {
      const newCache = { ...prevCache };
      delete newCache[monthKey];
      return newCache;
    });

    setCalendarData([]);
    setFilteredProperties([]);
    setProperties([]);
    setPropertyCount(0);
    setLoadedPages([]);
    loadedPagesRef.current = [];

    await fetchCalendarDataForMonth(monthName, year, 1, true);
    await fetchRemainingPages(monthName, year, true);

    setSpecificDate(date);
    setNewlyAddedJobId(newJobId);
    setNeedToScrollToSpecificDate(true);
  };

  const handleReservationCancelled = async (date: string | undefined, reservationId: string | null) => {
    closeBookingSidebar();

    const newDateMoment = moment(date, 'YYYY-MM-DD');
    const monthName = newDateMoment.format('MMMM');
    const year = newDateMoment.format('YYYY');

    const monthIndex = newDateMoment.month();
    setDates(generateDatesForMonth(Number(year), monthIndex));
    setCurrentMonth(`${monthName} ${year}`);

    setNeedToScrollToFirstDate(true);

    const monthKey = `${monthName} ${year}`;
    setCache((prevCache) => {
      const newCache = { ...prevCache };
      delete newCache[monthKey];
      return newCache;
    });

    setCalendarData([]);
    setFilteredProperties([]);
    setProperties([]);
    setPropertyCount(0);
    setLoadedPages([]);
    loadedPagesRef.current = [];

    await fetchCalendarDataForMonth(monthName, year, 1, true);
    await fetchRemainingPages(monthName, year, true);

    setSpecificDate(date);
    setNeedToScrollToSpecificDate(true);
  };

  const displayedProperties = React.useMemo(() => {
    if (!filteredSearchTerm) return filteredProperties;
    return filteredProperties.filter((property) =>
      (property?.name?.toLowerCase().includes(filteredSearchTerm.toLowerCase()))
      || property?.address?.toLowerCase().includes(filteredSearchTerm.toLowerCase())
    );
  }, [filteredSearchTerm, filteredProperties]);

  const TOTAL_PROPERTIES = 10;
  // const skeletonCount = Math.max(TOTAL_PROPERTIES - propertyCount, 0);
  const skeletonCount = TOTAL_PROPERTIES;

  return (
    <>
      <div className="multicalendar-header-container">
        <div className="search-listings">
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Search Listings"
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>
        </div>
        <div className="calendar-header">
          <div className="date-filter-container">
            <div className="date-filter">
              <button className="navi-btn" onClick={() => handleMonthChange('prev')} disabled={loadingSecond || isFetchingMore} style={{
                cursor: isFetchingMore || loadingSecond ? 'progress' : 'pointer'
              }}>
                <img src={leftIcon} alt="left-button" className='left-icon' />
              </button>
              <CustomMonthDropdown
                options={monthOptions}
                selectedOption={currentMonth}
                onSelect={handleMonthSelectChange}
                disabled={loading || isFetchingMore}
              // disabled={false}
              />
              <button className="navi-btn" onClick={() => handleMonthChange('next')} disabled={loadingSecond || isFetchingMore} style={{
                cursor: isFetchingMore || loadingSecond ? 'progress' : 'pointer'
              }}>
                <img src={rightIcon} alt="right-button" className='right-icon' />
              </button>
            </div>
            <div>
              <button className="secondary-btn ml-24" onClick={handleTodayClick} disabled={loading || isFetchingMore}>
                Today
              </button>
            </div>
          </div>
          <div className="another-button">
            {isFiltered && (
              <button className="secondary-btn mr-8" onClick={resetFilter}>
                Reset
              </button>
            )}
            <button className="primary-btn" onClick={toggleModal}>
              Availability
            </button>
          </div>
        </div>
      </div>

      <div className="multicalendar-container">
        <div className="listings">
          <div className="listings-header">
            {propertyCount} Properties {searchTerm ? 'Found' : 'Active'}
          </div>
          {displayedProperties.map((property) => (
            <Listing key={property.id} listing={property} />
          ))}

          {(loading || isFetchingMore) && Array.from({ length: skeletonCount }).map((_, index) => (
            <SkeletonRow key={index} type='listing' />
          ))}
        </div>
        <Calendar
          ref={calendarRef}
          listings={calendarData.filter((cd) =>
            displayedProperties.some((fp) => fp.id === cd.calendarPropertyId)
          )}
          dates={dates}
          properties={properties}
          loading={loading || isPending}
          isFetchingMore={isFetchingMore}
          skeletonCount={skeletonCount}
          onBookingClick={openBookingSidebar}
          onJobClick={openJobSidebar}
          onReservationClick={openReservationSidebar}
          onJobDetailClick={openJobDetailSidebar}
          onMoreJobDetailClick={openMoreDetailSidebar}
          selectedRange={selectedRange}
          newlyAddedReservationId={newlyAddedReservationId}
          newlyAddedJobId={newlyAddedJobId}
        />
      </div>

      {isFetchingMore && (
        // <div className="fetching-more-indicator">
        //   <div className="spinner-message"></div>
        //   <div>Please wait, loading more data for this month...</div>
        // </div>
        <></>
      )}

      {error && (
        <div className="fetching-more-indicator">
          <div className="retry"></div>
          <div>{error}</div>
        </div>
      )}

      {isRetrying && (
        // <div className="fetching-more-indicator">
        //   <div className="spinner-message"></div>
        //   <div>Please wait, reloading more data for this month...</div>
        // </div>
        <></>
      )}

      {isModalOpen && <FindAvailabilityModal onClose={toggleModal} updateProperties={updateProperties} />}

      {/* Sidebars */}
      {isBookingSidebarOpen && (
        <BookingSidebar
          reservationId={selectedBooking?.reservationPMS?.id}
          guestName={selectedBooking?.reservationPMS?.guestFullName}
          isManualBlock={selectedBooking?.reservationPMS?.otaName === 'Manual Block'}
          isAdvanceNotice={selectedBooking?.reservationPMS?.otaName === 'Advance Notice'}
          isAfterBlock={selectedBooking?.reservationPMS?.otaName === 'After Block'}
          isBeforeBlock={selectedBooking?.reservationPMS?.otaName === 'Before Block'}
          isRollingWindow={selectedBooking?.reservationPMS?.otaName === 'Rolling Window' || selectedBooking?.reservationPMS?.otaName === 'Rolling window'}
          arrivalDate={selectedBooking?.reservationPMS?.arrivalDate}
          departureDate={selectedBooking?.reservationPMS?.departureDate}
          defaultJobId={selectedBooking?.jobList?.[0]?.bubbleTaskId}
          onClose={closeBookingSidebar}
          sideBar={isBookingSidebarOpen}
          propertyPassed={selectedProperty}
          onReservationCancel={handleReservationCancelled}
        />
      )}

      {isJobSidebarOpen && (
        <JobSidebar
          selectedDate={selectedDate}
          onClose={closeJobSidebar}
          sideBar={isJobSidebarOpen}
          property={selectedProperty}
          onJobCreated={handleJobCreated}
        />
      )}

      {isReservationSidebarOpen && (
        <ReservationSidebar
          onClose={closeReservationSidebar}
          sideBar={isReservationSidebarOpen}
          selectedDate={selectedDate}
          property={selectedProperty}
          onReservationCreated={handleReservationCreated}
        />
      )}

      {isJobDetailSidebarOpen && selectedJob && (
        <JobDetailSidebar
          onClose={closeJobDetailSidebar}
          sideBar={isJobDetailSidebarOpen}
          jobDetail={selectedJob}
          property={selectedProperty}
        />
      )}

      {
        isMoreDetailSidebarOpen && currentJobs && (
          <MoreJobDetailSidebar
            onClose={closeMoreDetailSidebar}
            sideBar={isMoreDetailSidebarOpen}
            jobs={currentJobs}
            property={selectedProperty}
          />
        )
      }

    </>
  );
};

export default React.memo(CalendarApp);
