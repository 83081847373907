import React, { forwardRef, useRef, useImperativeHandle, useEffect, useState, useMemo } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import DayCell from './DayCell';
import { CalendarPMS, PropertyList } from '../api/type';
import SkeletonRow from './dropdown/SkeletonRow';

interface ListingType {
  calendarPMSList: CalendarPMS[];
  calendarPropertyId: string;
  propertyAddress: string | null;
  propertyTitle: string | null;
}

interface Job {
  id: string;
  bubbleTaskId: string | null;
  jobType: 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';
  status: string;
  date: string;
  startDate: string;
  endDate: string;
  duration: string;
}

interface CalendarProps {
  listings: ListingType[];
  dates: string[];
  loading: boolean;
  isFetchingMore: boolean;
  skeletonCount: number;
  properties: PropertyList[];
  onBookingClick: (property: PropertyList | undefined, booking: CalendarPMS) => void;
  onJobClick: (property: PropertyList | undefined, date: string) => void;
  onReservationClick: (property: PropertyList | undefined, date: string) => void;
  onJobDetailClick: (job: Job, property: PropertyList | undefined) => void;
  onMoreJobDetailClick: (jobs: Job[], property: PropertyList | undefined) => void;
  selectedRange?: {
    startDate: string;
    endDate: string;
  } | null;
  newlyAddedReservationId: string | null;
  newlyAddedJobId: string | null;
}

const Calendar = forwardRef(({ listings, dates, loading, isFetchingMore, skeletonCount, properties, onBookingClick, onJobClick, onReservationClick, onJobDetailClick, onMoreJobDetailClick, selectedRange, newlyAddedReservationId, newlyAddedJobId }: CalendarProps, ref) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState(false);
  // console.log("listing", listings);
  // console.log("properties", properties);

  const propertyMap = useMemo(() => {
    return new Map(properties.map(property => [property.id, property]));
  }, [properties]);

  useImperativeHandle(ref, () => ({
    scrollToCurrentDate: () => {
      const today = moment().format('YYYY-MM-DD');
      const currentDateIndex = dates.findIndex(date => moment(date).format('YYYY-MM-DD') === today);
      if (calendarContainerRef.current && currentDateIndex !== -1) {
        const dateElement = calendarContainerRef.current.querySelector(`.date-header-cell-${currentDateIndex}`);
        if (dateElement) {
          dateElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
      }
    },

    scrollToFirstDate: () => {
      if (calendarContainerRef.current) {
        const dateElement = calendarContainerRef.current.querySelector(`.date-header-cell-0`);
        if (dateElement) {
          dateElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        }
      }
    },

    scrollToSpecificDate: (targetDate: string) => {
      const formattedTargetDate = moment(targetDate).format('YYYY-MM-DD');
      const targetDateIndex = dates.findIndex(date => moment(date).format('YYYY-MM-DD') === formattedTargetDate);
      if (calendarContainerRef.current && targetDateIndex !== -1) {
        const dateElement = calendarContainerRef.current.querySelector(`.date-header-cell-${targetDateIndex}`);
        if (dateElement) {
          dateElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
        }
      }
    },

    scrollToSpecificProperty: (propertyId: string) => {
      if (calendarContainerRef.current) {
        const propertyElement = calendarContainerRef.current.querySelector(
          `.property-row-${propertyId}`
        );
        if (propertyElement) {
          propertyElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
      }
    }
  }));

  const isDateInRange = (date: string) => {
    if (!selectedRange || !selectedRange.startDate || !selectedRange.endDate) return true;
    const momentDate = moment(date, 'YYYY-MM-DD');
    const startDate = moment(selectedRange.startDate, 'YYYY-MM-DD');
    const endDate = moment(selectedRange.endDate, 'YYYY-MM-DD');
    return momentDate.isBetween(startDate, endDate, null, '[]');
  };


  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const headerTop = headerRef.current.getBoundingClientRect().top;
        if (headerTop <= 0 && !isFixed) {
          setIsFixed(true);
        } else if (window.scrollY < headerRef.current.offsetTop && isFixed) {
          setIsFixed(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFixed]);

  return (
    <div className="calendar" ref={calendarContainerRef}>
      <div className="calendar-date-header">
        {dates.map((date, index) => (
          <div
            key={date}
            className={`date-header-cell date-header-cell-${index} ${date === moment().format('YYYY-MM-DD') ? 'today' : ''} ${isDateInRange(date) ? 'in-range' : 'out-of-range'}`}
          >

            {/* ${selectedRange && isDateInRange(date) ? '' : ''} ${selectedRange && !isDateInRange(date) ? 'dim' : ''} */}
            <Moment format="ddd,  MMM D">{date}</Moment>
            {date === moment().format('YYYY-MM-DD') && <span className="today-label">Today</span>}
          </div>
        ))}
      </div>

      <div className="calendar-bdy">
        {loading ? (
          <div className="loading-indicator">
            <div className="spinner"></div>
          </div>
        ) : listings.length === 0 ? (
          <div className="no-properties-message">
            No properties available on the selected dates.
          </div>
        ) : (
          <>
            {listings.map(listing => {
              // const property = properties.find(p => p.id === listing.calendarPropertyId);
              return (
                <div key={listing.calendarPropertyId} className={`calendar-row property-row-${listing.calendarPropertyId}`}>
                  {dates.map(date => (
                    <DayCell
                      key={date}
                      date={date}
                      listing={listing}
                      property={propertyMap.get(listing.calendarPropertyId)}
                      onBookingClick={onBookingClick}
                      onJobClick={onJobClick}
                      onReservationClick={onReservationClick}
                      onJobDetailClick={onJobDetailClick}
                      onMoreJobDetailClick={onMoreJobDetailClick}
                      isInRange={isDateInRange(date)}
                      newlyAddedReservationId={newlyAddedReservationId}
                      newlyAddedJobId={newlyAddedJobId}
                    />
                  ))}
                </div>
              );
            })}

            {isFetchingMore && (
              <div className="fetching-more-skeletons">
                {Array.from({ length: skeletonCount }).map((_, index) => (
                  <SkeletonRow key={index} type='calendar' />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default React.memo(Calendar);
