import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import CalendarApp from "./components/CalendarApp";
import { authenticateimportantOrgId } from "./api/request";

export const ImportantOrgContext = createContext<string>("");

const App: React.FC = () => {

  const [importantOrgId, setImportantOrgId] = useState("");

  const checkAuth = async (token: string, userId: string, importantOrgId: string) => {
    try {
      const isAuthenticated: boolean = await authenticateimportantOrgId(token, userId, importantOrgId);
      console.log("Authentication Status: " + isAuthenticated);
      if (isAuthenticated) {
        setImportantOrgId(importantOrgId);
      }
    } catch (error) {
      console.error('Authentication Error: ', error);
    }
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const importantOrgId: string = params.get("importantOrgId") || "";
    const userId: string = params.get("userId") || "";
    const token: string = params.get("token") || "";

    checkAuth(token, userId, importantOrgId);
  }, [])


  return (
    <div className="App">
      <ImportantOrgContext.Provider value={importantOrgId}>
        {importantOrgId === "" ? <p></p> : <CalendarApp />}
      </ImportantOrgContext.Provider>
    </div>
  );
};

export default App;
