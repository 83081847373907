import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { createNewReservation, createManualBlock, createOwnerBlock, fetchPropertyOwner, fetchAccommodationFee, fetchAvailability } from '../../api/request';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  CreateNewReservationRequest,
  CreateManualBlockRequest,
  CreateOwnerBlockRequest,
  PropertyList,
  Owner
} from '../../api/type';
import moment from 'moment';

import airbnbImage from '../assets/airbnb.svg';
import ownerBlockImage from '../assets/ownerblock.png';
import bookingImage from '../assets/booking.svg';
import directBookingImage from '../assets/direct.png';
import xIcon from '../assets/x.svg';

import homeDark from '../assets/home-dark.svg';

interface ChannelOption {
  value: string;
  label: string;
  icon: string;
}

interface CustomDropdownProps {
  options: { [key: string]: ChannelOption };
  value: string;
  onChange: (value: string) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  return (
    <div className="custom-select icon-select">
      <div className="selected-value" onClick={() => setIsOpen(!isOpen)}>
        <img src={options[value].icon} alt={`${options[value].label} icon`} />
        <div>{options[value].label} </div>
      </div>
      {isOpen && (
        <div className="options">
          {Object.keys(options).map((key) => (
            <div
              className="option"
              key={key}
              onClick={() => handleOptionClick(key)}
            >
              <img src={options[key].icon} alt={`${options[key].label} icon`} />
              <div>{options[key].label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

interface SidebarProps {
  onClose: () => void;
  sideBar: boolean;
  selectedDate: string;
  property: PropertyList | undefined;
  onReservationCreated: (date: string, newReservationId: string | null, propertyId: string) => void;
}

type JobType = 'cleaning' | 'maintenance';

const channel = {
  airbnb: 'Airbnb',
  booking: 'Booking.com',
  direct: 'Direct',
  manual: 'Manual Block',
  owner: 'Owners Block'
} as const;

const daysOfWeek = [
  { full: 'SUNDAY', short: 'Su' },
  { full: 'MONDAY', short: 'Mo' },
  { full: 'TUESDAY', short: 'Tu' },
  { full: 'WEDNESDAY', short: 'We' },
  { full: 'THURSDAY', short: 'Th' },
  { full: 'FRIDAY', short: 'Fr' },
  { full: 'SATURDAY', short: 'Sa' },
];

const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isPhoneNumberValid = (phone: string) => {
  const phoneRegex = /^\+?[1-9]\d{8,9}$/;
  return phoneRegex.test(phone);
};

const isDateValid = (date: string) => {
  return !isNaN(Date.parse(date));
};

const formatChannel = (channel: string) => {
  switch (channel) {
    case 'airbnb':
      return 'Airbnb';
    case 'direct':
      return 'Direct';
    case 'booking':
      return 'Booking.com';
    case 'manual':
      return 'Manual Block';
    case 'owner':
      return 'Owners Block';
    default:
      return channel;
  }
};

const channelOptions: { [key: string]: ChannelOption } = {
  airbnb: { label: 'Airbnb', icon: airbnbImage, value: 'airbnb' },
  booking: { label: 'Booking.com', icon: bookingImage, value: 'booking' },
  direct: { label: 'Direct', icon: directBookingImage, value: 'direct' },
  manual: { label: 'Manual Block', icon: ownerBlockImage, value: 'manual' },
  owner: { label: 'Owners Block', icon: ownerBlockImage, value: 'owner' },
};

const ReservationSidebar: React.FC<SidebarProps> = ({ onClose, sideBar, selectedDate, property, onReservationCreated }) => {
  const [formData, setFormData] = useState({
    selectedJobType: 'cleaning' as JobType,
    selectedGuest: '',
    selectedChannel: 'airbnb',
    guestEmail: '',
    guestPhone: '',
    guests: '1',
    checkInDate: selectedDate,
    checkOutDate: '',
    notes: '',
    cleaningFee: property?.guestCleaningFee?.toString() || '',
    accommodationFee: '',
    repeat: false,
    repeatInterval: 1,
    repeatUnit: 'DAILY',
    selectedDays: [] as string[],
    ends: 'never',
    endDate: '',
    occurrences: ''
  });

  const [ownerData, setOwnerData] = useState<Owner | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [accommodationFeeError, setAccommodationFeeError] = useState<string>('');
  const [availability, setAvailability] = useState<string>('');
  const errorRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);

  const handleDaySelection = (day: string) => {
    setFormData(prevData => ({
      ...prevData,
      selectedDays: prevData.selectedDays.includes(day)
        ? prevData.selectedDays.filter(d => d !== day)
        : [...prevData.selectedDays, day]
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    const trimmedValue = value.replace(/\s+/g, ' ');

    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData(prevData => ({
        ...prevData,
        [name]: checked
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: trimmedValue
      }));
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
  };

  const handleChannelChange = (selectedChannel: string) => {
    setFormData(prevData => ({
      ...prevData,
      selectedChannel,
    }));
  };

  const handleDateChange = (date: Date | null, name: string) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: date ? moment(date).format('YYYY-MM-DD') : ''
    }));

    if (name === 'checkInDate' || name === 'checkOutDate' || name === 'endDate') {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };


  useEffect(() => {
    const fetchOwnerData = async () => {
      if (formData.selectedChannel === 'owner' && property?.id) {
        try {
          const response = await fetchPropertyOwner(property.id);
          if (response.success && response.data) {
            setOwnerData(response.data);
            setFormData(prevData => ({
              ...prevData,
              selectedGuest: response.data ? response.data["Full Name"] : '',
              guestEmail: response.data ? response.data["Email Address"] : '',
              guestPhone: response.data ? response.data["Mobile Number"] : ''
            }));
          } else {
            setOwnerData(null);
          }
        } catch (error) {
          console.error('Error fetching owner data:', error);
          setOwnerData(null);
        }
      }
    };

    fetchOwnerData();
  }, [formData.selectedChannel, property?.id]);

  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]);

  useEffect(() => {
    if (success && successRef.current) {
      successRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [success]);


  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!property) newErrors.propertyListing = 'Property Listing is required.';
    if (!formData.selectedGuest && formData.selectedChannel !== 'manual' && formData.selectedChannel !== 'owner') newErrors.selectedGuest = 'Guest Name is required.';
    if (formData.guestEmail && !isEmailValid(formData.guestEmail)) newErrors.guestEmail = 'Invalid email format.';
    if (!formData.selectedGuest && formData.selectedChannel !== 'manual') newErrors.selectedGuest = 'Guest Name is required';
    if (!formData.guestEmail && formData.selectedChannel !== 'manual') newErrors.guestEmail = 'Guest Email is required';
    if (!formData.guestPhone && formData.selectedChannel !== 'manual') newErrors.guestPhone = 'Guest Phone is required';
    if (formData.guestPhone && !isPhoneNumberValid(formData.guestPhone)) newErrors.guestPhone = 'Invalid phone number format.';
    if (!formData.guests && formData.selectedChannel !== 'manual') newErrors.guests = 'Guest count is required.';
    if (!formData.checkInDate) newErrors.checkInDate = 'Check In Date is required.';
    if (formData.checkInDate && !isDateValid(formData.checkInDate)) newErrors.checkInDate = 'Invalid Check In Date.';
    if (!formData.checkOutDate) newErrors.checkOutDate = 'Check Out Date is required.';
    if (formData.checkOutDate && !isDateValid(formData.checkOutDate)) newErrors.checkOutDate = 'Invalid Check Out Date.';
    if (!formData.notes && (formData.selectedChannel === 'manual' || formData.selectedChannel === 'owner')) newErrors.notes = 'Block Notes are required.';
    if (parseFloat(formData.accommodationFee) < 0) newErrors.accommodationFee = 'Accommodation Fee must be a positive number.';
    if (parseFloat(formData.cleaningFee) < 0) newErrors.cleaningFee = 'Cleaning Fee must be a positive number.';

    if (formData.checkInDate && formData.checkOutDate) {
      const checkIn = new Date(formData.checkInDate);
      const checkOut = new Date(formData.checkOutDate);
      const durationInDays = (checkOut.getTime() - checkIn.getTime()) / (1000 * 60 * 60 * 24);

      if (checkIn > checkOut) newErrors.checkInDate = 'Check In Date cannot be after Check Out Date.';
      if (checkIn == checkOut) newErrors.checkInDate = 'Check In Date cannot be same as Check Out Date.';
      if (checkOut < checkIn) newErrors.checkOutDate = 'Check Out Date cannot be before Check In Date.';

      if (formData.selectedChannel !== 'owner' && formData.selectedChannel !== 'manual' && property) {
        const minNight = property.minNights;
        const maxNights = property.maxNights;

        if ((minNight && minNight > 0) && (durationInDays < minNight)) {
          newErrors.duration = `Minimum stay is ${minNight} night(s).`;
        }
        if ((maxNights && maxNights > 0) && (durationInDays > maxNights)) {
          newErrors.duration = `Maximum stay is ${maxNights} night(s).`;
        }
      }
    }

    if (property && formData.selectedChannel !== 'manual') {
      if (parseInt(formData.guests, 10) > parseInt(property.guestCount, 10)) {
        newErrors.guests = `Total guests exceed the max capacity of ${property.guestCount}.`;
      }
    }

    if (formData.repeat) {
      if (formData.ends !== 'on' && formData.ends !== 'after') {
        newErrors.ends = 'Please select either "Ends on" or "Ends after".';
      } else {
        if (formData.ends === 'on' && !formData.endDate) {
          newErrors.endDate = 'End date is required.';
        } else if (formData.ends === 'on' && new Date(formData.endDate) <= new Date(selectedDate)) {
          newErrors.endDate = 'End date must be after the start date.';
        }

        if (formData.ends === 'after' && !formData.occurrences) {
          newErrors.occurrences = 'Occurrences are required.';
        } else if (formData.ends === 'after' && parseInt(formData.occurrences) <= 0) {
          newErrors.occurrences = 'Occurrences must be a positive number.';
        }
      }

    }

    if (formData.repeatUnit === 'WEEKLY' && formData.selectedDays.length === 0) {
      newErrors.selectedDays = 'At least one day must be selected for weekly repeats.';
    }

    if (availability === 'Unavailable') {
      newErrors.availability = 'Selected dates are unavailable.';
    }



    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccess(false);
    setError('');

    if (validateForm()) {
      setLoading(true);
      try {
        if (!property) {
          setError('Property is required.');
          setLoading(false);
          return;
        }

        let date = new Date().getTimezoneOffset() * -1;
        let hour = Math.floor(date / 60);
        let hourString = hour > 9 ? hour + '' : `0${hour}`;

        let minute = date % 60 > 9 ? date % 60 + '' : `0${date % 60}`;

        const checkInDateTime = moment(`${formData.checkInDate} ${hourString}:${minute}`, "YYYY-MM-DD HH:mm").format();
        const checkOutDateTime = moment(`${formData.checkOutDate} ${hourString}:${minute}`, "YYYY-MM-DD HH:mm").format();

        // const checkInDateTime = `${formData.checkInDate}T00:00:00`;
        // const checkOutDateTime = `${formData.checkOutDate}T00:00:00`;

        // const checkInDateTime = moment.utc(`${formData.checkInDate} 15:00`, "YYYY-MM-DD HH:mm").format();
        // const checkOutDateTime = moment.utc(`${formData.checkOutDate} 10:00`, "YYYY-MM-DD HH:mm").format();


        let newReservationId: string | null = null;

        const formattedGuestPhone = formData.guestPhone.startsWith("+61")
          ? formData.guestPhone
          : `+61${formData.guestPhone}`;

        if (formData.selectedChannel === 'manual') {
          const manualBlockDetails: CreateManualBlockRequest = {
            propertyId: property.id,
            guestName: 'Manual Block',
            guestCount: 1,
            guestEmail: 'mail@test.com',
            adultGuestCount: 1,
            guestNotes: formData.notes,
            guestMobile: "+614322134690",
            checkinDate: formData.checkInDate,
            checkoutDate: formData.checkOutDate,
            startTime: "03:00 PM",
            endTime: "10:00 AM",
            repeatDaysInWeek: formData.repeat ? formData.selectedDays : [],
            endDate: formData.repeat && formData.ends === 'on' ? formData.endDate : "",
            totalOccurrences: formData.repeat
              ? formData.ends === 'after'
                ? parseInt(formData.occurrences) || 1
                : formData.ends === 'on'
                  ? 0
                  : 1
              : 1,
            repeatEvery: formData.repeat ? formData.repeatUnit.toUpperCase() : "DAILY",
            propertyAddress: property.address || 'sample-address',
          };

          // console.log("manual block passed", manualBlockDetails);

          const response = await createManualBlock(manualBlockDetails);
          // console.log(response);

          if (response.success && response.data) {
            newReservationId = response.data.reservationId;
          }
        } else if (formData.selectedChannel === 'owner') {
          const ownerBlockDetails: CreateOwnerBlockRequest = {
            bubblePropertyId: property.id,
            checkInDate: checkInDateTime,
            checkOutDate: checkOutDateTime,
            guestCount: parseInt(formData.guests, 10),
            adultGuestCount: parseInt(formData.guests, 10),
            guestName: formData.selectedGuest,
            guestEmail: formData.guestEmail,
            guestNotes: formData.notes,
            guestMobile: formData.guestPhone,
            reservationCode: 'N/A',
            propertyAddress: 'N/A',
            bubbleReservationId: 'N/A',
          };

          // console.log(ownerBlockDetails);
          const response = await createOwnerBlock(ownerBlockDetails);
          // console.log(response);

          if (response.success && response.data) {
            newReservationId = response.data.reservationId;
          }

        } else {
          const newReservationDetails: CreateNewReservationRequest = {
            bubblePropertyId: property.id,
            bubbleReservationId: '',
            checkInDate: checkInDateTime,
            checkOutDate: checkOutDateTime,
            ratePlan: 'Standard',
            guestName: formData.selectedGuest,
            accomodationFee: parseFloat(formData.accommodationFee) || 0,
            cleaningFee: parseFloat(formData.cleaningFee) || 0,
            guestCount: parseInt(formData.guests, 10),
            guestEmail: formData.guestEmail,
            adultGuestCount: parseInt(formData.guests, 10),
            channel: formatChannel(formData.selectedChannel),
            guestNotes: formData.notes,
            guestMobile: formattedGuestPhone,
            reservationCode: '',
            guestPersonalLocation: {
              address: 'N/A',
              lat: 0,
              lng: 0,
            },
            propertyAddress: property.address,
            reservationPropertyId: '',
            propertyId: property.id,
            statusOption: '',
            totalFee: (parseFloat(formData.accommodationFee) || 0) + (parseFloat(formData.cleaningFee) || 0),
            error: false,
            errorMessage: '',
          };

          // console.log(newReservationDetails);
          const response = await createNewReservation(newReservationDetails);
          // console.log(response);

          if (response.success && response.data) {
            newReservationId = response.data.reservationId;
          }
        }

        setSuccess(true);

        setTimeout(() => {
          onReservationCreated(formData.checkInDate, newReservationId, property?.id || '');
        }, 2000);

        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);

        setFormData({
          selectedJobType: 'cleaning',
          selectedGuest: '',
          selectedChannel: 'airbnb',
          guestEmail: '',
          guestPhone: '',
          guests: '1',
          checkInDate: selectedDate,
          checkOutDate: '',
          notes: '',
          cleaningFee: property?.guestCleaningFee?.toString() || '',
          accommodationFee: '',
          repeat: false,
          repeatInterval: 1,
          repeatUnit: 'WEEKLY',
          selectedDays: [],
          ends: 'never',
          endDate: '',
          occurrences: ''
        });
      } catch (error) {
        console.error('Error submitting the form', error);
        setError('Failed to create reservation. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (formData.checkInDate && formData.checkOutDate && property?.id) {
      setAccommodationFeeError('');
      const adjustedCheckOutDate =
        formData.checkInDate === formData.checkOutDate
          ? moment(formData.checkOutDate).add(1, 'day').format("YYYY-MM-DD")
          : formData.checkOutDate;
      const fetchFee = async () => {
        try {
          const response = await fetchAccommodationFee(formData.checkInDate, adjustedCheckOutDate, property.id);
          if (response.success && response.data) {
            setFormData(prevData => ({
              ...prevData,
              accommodationFee: response.data ? response.data.price.toString() : ''
            }));
          } else {
            setAccommodationFeeError('Failed to fetch accommodation fee.');
          }
        } catch (error) {
          setAccommodationFeeError('Failed to fetch accommodation fee.');
        }
      };

      fetchFee();

      const checkAvailability = async () => {
        try {
          const checkInDate = `${formData.checkInDate}T00:00:00`;
          const checkOutDate = `${formData.checkOutDate}T00:00:00`;
          const response = await fetchAvailability(property.id, checkInDate, checkOutDate);
          if (response.reservationIsAvailable) {
            setAvailability('Available');
          } else {
            setAvailability('Unavailable');
          }
        } catch (error) {
          setAvailability('Failed to fetch availability.');
        }
      };

      checkAvailability();
    } else {
      if (!formData.checkInDate || !formData.checkOutDate) {
        setAccommodationFeeError('Please select Check-in and Check-out dates');
      } else {
        setAccommodationFeeError('');
      }
      setAvailability('Select Dates');
    }
  }, [formData.checkInDate, formData.checkOutDate, property?.id]);


  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccess(false);
        setError('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [success, error]);

  return (
    <div className={`sidebar ${sideBar ? 'open' : ''}`}>
      <div className="sidebar-header" ref={successRef}>
        <h2>Create A Reservation</h2>
        <div className="sidebar-header-icons" ref={errorRef}>
          <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className='x-icon' /></button>
        </div>
      </div>
      {success && (
        <div className="success-message sm-20">
          <FontAwesomeIcon icon={faCheckCircle} /> Reservation successfully created!
        </div>
      )}
      {error && (
        <div className="error-message">
          <FontAwesomeIcon icon={faTimesCircle} /> {error}
        </div>
      )}

      <div className="sidebar-body">
        <form onSubmit={handleSubmit} className="sidebar-content-job">
          <div>
            <div className='job-row'>
              <div className='booking-column'>
                <div className="booking-label">Date</div>
                <div className="booking-value">{moment(selectedDate).format('MMM DD, YYYY')}</div>
              </div>
            </div>
            <div className="task-form px-3">
              <div className="form-group">
                <label htmlFor="property">Property Listing</label>
                <div className="property-row mt-1 pointer" onClick={() => window.open(`https://mypropertystack.io/property_details/${property?.id}`, ' _blank')}>
                  <div className="property-nou">
                    <div className={property?.image ? "listing-image" : "listing-image-none"}>
                      <img src={property?.image || homeDark} alt={property?.name || 'Property'} />
                    </div>
                    <div>
                      <h3>{property?.name}</h3>
                      <p>{property?.address}</p>
                    </div>
                  </div>
                </div>
                <div className='booking-row-second'>
                  <div className='booking-column'>
                    <div className="booking-label">Status</div>
                    <div className={`booking-value ${availability === 'Available' ? 'confirmed' : availability === 'Unavailable' ? 'not-confirmed' : ''}`}>{availability}</div>


                    {errors.availability && <div className="error-message">{errors.availability}</div>}
                  </div>
                  <div className='booking-column'>
                    <div className="booking-label">Max Capacity</div>
                    <div className="booking-value">{property?.guestCount} Guests</div>
                  </div>
                </div>
              </div>
              {/* <div className="form-group">
                <label htmlFor="channel">Channel*</label>
                <div className="custom-select icon-select">
                  <select
                    id="channel"
                    name="selectedChannel"
                    value={formData.selectedChannel}
                    onChange={handleChange}
                  >
                    {Object.keys(channel).map((key) => (
                      <option value={key} key={key}>
                        {channel[key as keyof typeof channel]}
                      </option>
                    ))}
                  </select>
                  <img src={getImageForBookingChannel(formData.selectedChannel as keyof typeof channel)} alt="Channel Icon" className="channel-icon" />
                  {errors.selectedChannel && <div className="error-message">{errors.selectedChannel}</div>}
                </div>
              </div> */}

              <div className="form-group">
                <label htmlFor="channel">Channel*</label>
                <CustomDropdown
                  options={channelOptions}
                  value={formData.selectedChannel}
                  onChange={handleChannelChange}
                />
                {errors.selectedChannel && <div className="error-message">{errors.selectedChannel}</div>}
              </div>


              {formData.selectedChannel !== 'manual' && (
                <>
                  <div className="form-group">
                    <label htmlFor="guestName">Guest Name*</label>
                    <input
                      type="text"
                      id="guestName"
                      name="selectedGuest"
                      value={formData.selectedGuest}
                      placeholder={formData.selectedChannel === 'owner' && ownerData ? ownerData["Full Name"] : "Enter Guest Name"}
                      onChange={handleChange}
                    />
                    {errors.selectedGuest && <div className="error-message">{errors.selectedGuest}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="guestEmail">Guest Email*</label>
                    <input
                      type="text"
                      id="guestEmail"
                      name="guestEmail"
                      value={formData.guestEmail}
                      placeholder={formData.selectedChannel === 'owner' && ownerData ? ownerData["Email Address"] : "Enter Guest Email"}
                      onChange={handleChange}
                    />
                    {errors.guestEmail && <div className="error-message">{errors.guestEmail}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="guestPhone">Guest Phone*</label>
                    <div className="phone-input">
                      <input
                        type="text"
                        id="guestPhone"
                        name="guestPhone"
                        className="no-spinner"
                        value={formData.guestPhone}
                        placeholder={
                          formData.selectedChannel === 'owner' && ownerData
                            ? ownerData["Mobile Number"]
                            : "Enter Guest Phone"
                        }
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, '');
                          setFormData((prevData) => ({
                            ...prevData,
                            guestPhone: value.length > 10 ? value.slice(0, 10) : value,
                          }));
                        }}
                      />
                    </div>
                    {errors.guestPhone && <div className="error-message">{errors.guestPhone}</div>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="guests">Guests*</label>
                    <input
                      type="number"
                      id="guests"
                      name="guests"
                      value={formData.guests}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {  // Only allow numeric characters
                          handleChange(e);
                        }
                      }}
                      placeholder="Enter number of guests"
                      min="1"
                      max={property?.guestCount || 10}
                    />
                    {errors.guests && <div className="error-message">{errors.guests}</div>}
                  </div>


                </>
              )}
              <div className="form-group">
                <label htmlFor="checkInDate">Check In Date*</label>
                {/* <input
                  type="date"
                  id="checkInDate"
                  name="checkInDate"
                  value={formData.checkInDate || ""}
                  onChange={handleChange}
                /> */}
                <DatePicker
                  selected={formData.checkInDate ? new Date(formData.checkInDate) : null}
                  onChange={(date) => handleDateChange(date, 'checkInDate')}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select Check In Date"
                  className='date-picker-half-width'
                />
                {errors.checkInDate && <div className="error-message">{errors.checkInDate}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="checkOutDate">Check Out Date*</label>
                {/* <input
                  type="date"
                  id="checkOutDate"
                  name="checkOutDate"
                  value={formData.checkOutDate}
                  onChange={handleChange}
                /> */}
                <DatePicker
                  selected={formData.checkOutDate ? new Date(formData.checkOutDate) : null}
                  onChange={(date) => handleDateChange(date, 'checkOutDate')}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select Check Out Date"
                  minDate={formData.checkInDate ? new Date(new Date(formData.checkInDate).setDate(new Date(formData.checkInDate).getDate() + 1)) : undefined}
                  showDisabledMonthNavigation
                />
                {errors.checkOutDate && <div className="error-message">{errors.checkOutDate}</div>}
                {errors.duration && <div className="error-message">{errors.duration}</div>}
              </div>
              {formData.selectedChannel === 'manual' && (
                <div className="form-group">
                  <label htmlFor="notes">Block Notes*</label>
                  <textarea
                    id="notes"
                    name="notes"
                    value={formData.notes}
                    onChange={handleChange}
                    rows={4}
                  ></textarea>
                  {errors.notes && <div className="error-message">{errors.notes}</div>}
                </div>
              )}

              {formData.selectedChannel === 'owner' && (
                <div className="form-group">
                  <label htmlFor="notes">Block Notes*</label>
                  <textarea
                    id="notes"
                    name="notes"
                    value={formData.notes}
                    onChange={handleChange}
                  ></textarea>
                  {errors.notes && <div className="error-message">{errors.notes}</div>}
                </div>
              )}

              {formData.selectedChannel === 'manual' && (
                <>
                  <div className="form-group">
                    <label>Repeat</label>
                    <div className="switch-container">
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="repeat"
                          checked={formData.repeat}
                          onChange={handleChange}
                        />
                        <span className="slider"></span>
                      </label>
                      <span className="switch-label">{formData.repeat ? 'Yes' : 'No'}</span>
                    </div>
                  </div>

                  {formData.repeat && (
                    <>
                      <div className="form-group">
                        <label htmlFor="repeatInterval">Repeat every</label>
                        <div className="repeat-every">
                          {/* <input
                            type="number"
                            id="repeatInterval"
                            name="repeatInterval"
                            min="1"
                            value={formData.repeatInterval}
                            onChange={handleChange}
                          /> */}
                          <select
                            id="repeatIntervalUnit"
                            name="repeatUnit"
                            value={formData.repeatUnit}
                            onChange={handleChange}
                          >
                            <option value="DAILY">Daily</option>
                            <option value="WEEKLY">Weekly</option>
                            <option value="MONTHLY">Monthly</option>
                            <option value="YEARLY">Yearly</option>
                          </select>
                        </div>
                        {errors.repeatInterval && <div className="error-message">{errors.repeatInterval}</div>}
                      </div>

                      {formData.repeatUnit === "WEEKLY" && (
                        <div className="form-group">
                          <label>Repeat on</label>
                          <div className="repeat-days">
                            {daysOfWeek.map((day, index) => (
                              <label
                                key={index}
                                className={`day-checkbox ${formData.selectedDays.includes(day.full) ? "selected" : ""
                                  }`}
                              >
                                <input
                                  type="checkbox"
                                  value={day.full}
                                  checked={formData.selectedDays.includes(day.full)}
                                  onChange={() => handleDaySelection(day.full)}
                                />
                                {day.short}
                              </label>
                            ))}
                          </div>
                          {errors.selectedDays && <div className="error-message">{errors.selectedDays}</div>}
                        </div>
                      )}


                      <div className="form-group">
                        <label>Ends</label>
                        <div className="ends-options">
                          {/* <label>
                        <input
                          type="radio"
                          name="ends"
                          value="never"
                          checked={formData.ends === 'never'}
                          onChange={handleChange}
                        />
                        <span className="custom-radio"></span>
                        <span>Never</span>
                      </label> */}
                          <label>
                            <input
                              type="radio"
                              name="ends"
                              value="on"
                              checked={formData.ends === 'on'}
                              onChange={handleChange}
                            />
                            <span className="custom-radio"></span>
                            <span style={{ minWidth: '40%' }}>On</span>
                            {formData.ends === 'on' && (
                              // <input
                              //   type="date"
                              //   name="endDate"
                              //   value={formData.endDate}
                              //   onChange={handleChange}
                              //   className="ends-date-input"
                              //   style={{ width: '50%' }}
                              // />
                              <DatePicker
                                selected={formData.endDate ? new Date(formData.endDate) : null}
                                onChange={(date) => handleDateChange(date, 'endDate')}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Select End Date"
                              // className='date-picker-half-width'
                              />
                            )}
                            {/* {errors.endDate && <div className="error-message">{errors.endDate}</div>} */}
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="ends"
                              value="after"
                              checked={formData.ends === 'after'}
                              onChange={handleChange}
                            />
                            <span className="custom-radio"></span>
                            <span style={{ minWidth: '40%' }}>After</span>
                            {formData.ends === 'after' && (
                              <>
                                <input
                                  type="number"
                                  name="occurrences"
                                  value={formData.occurrences}
                                  onChange={handleChange}
                                  className="ends-occurrences-input"
                                  style={{ width: '50%' }}
                                />
                              </>
                            )}

                          </label>
                        </div>
                      </div>
                      {(errors.ends && <div className="error-message">{errors.ends}</div>)}
                      {(errors.endDate && <div className="error-message">{errors.endDate}</div>)}
                      {errors.occurrences && <div className="error-message">{errors.occurrences}</div>}

                    </>
                  )}
                </>
              )}

              {formData.selectedChannel !== 'manual' && formData.selectedChannel !== 'owner' && (
                <>
                  {/* <div className="form-group">
                    <label htmlFor="accommodationFee">Accommodation Fee</label>
                    <di></di>
                    <input
                      type="number"
                      id="accommodationFee"
                      name="accommodationFee"
                      value={formData.accommodationFee}
                      placeholder="Enter Accommodation Fee"
                      onChange={handleChange}
                    />
                    {errors.accommodationFee && <div className="error-message">{errors.accommodationFee}</div>}
                    {accommodationFeeError && <div className="error-message">{accommodationFeeError}</div>}
                  </div> */}

                  <div className="form-group">
                    <label htmlFor="accommodationFee">Accommodation Fee*</label>
                    <div className="rate-input">
                      <input
                        type="number"
                        id="accommodationFee"
                        name="accommodationFee"
                        className="no-spinner"
                        value={(parseFloat(formData.accommodationFee) || 0).toFixed(2)}
                        onChange={handleChange}
                        placeholder={(parseFloat(formData.accommodationFee) || 0).toFixed(2)}
                      />
                    </div>
                    {errors.accommodationFee && <div className="error-message">{errors.accommodationFee}</div>}
                    {accommodationFeeError && <div className="error-message">{accommodationFeeError}</div>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="cleaningFee">Cleaning Fee*</label>
                    <div className="rate-input">
                      <input
                        type="number"
                        id="cleaningFee"
                        name="cleaningFee"
                        value={formData.cleaningFee}
                        onChange={handleChange}
                        placeholder={(parseFloat(formData.cleaningFee) || 0).toFixed(2)}
                        className="no-spinner"
                      />
                    </div>
                    {errors.cleaningFee && <div className="error-message">{errors.cleaningFee}</div>}

                  </div>

                  <div className='booking-row'>
                    <div className='booking-column'>
                      <div className="booking-label">Total</div>
                      <div className="booking-value">$ {((parseFloat(formData.accommodationFee) || 0) + (parseFloat(formData.cleaningFee) || 0)).toFixed(2)} </div>
                    </div>
                  </div>

                </>
              )}
              <div className="modal-footer">
                <div className="form-actions">
                  <button type="submit" className="primary-btn font-500" disabled={loading}>
                    {loading ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin /> Processing...
                      </>
                    ) : (
                      'Create Reservation'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReservationSidebar;

